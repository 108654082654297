import websocketService from '../../../websocketService';
import {
  WEBSOCKET_EVENT_ADD_SUBSCRIBER,
  WEBSOCKET_EVENT_PUBLISH_MESSAGE,
  WEBSOCKET_EVENT_REMOVE_SUBSCRIBER,
} from '../mutations/mutationTypes';
import { SEND_WEBSOCKET_MESSAGE } from './actionTypes';

export default {
  async UPDATE_WEB_SOCKET_CONNECTION_ID({ commit }, connectionId) {
    commit('SET_WEB_SOCKET_CONNECTION_ID', connectionId);
  },

  async UPDATE_WEB_SOCKET_STATUS({ commit }, status) {
    commit('SET_WEBSOCKET_STATUS', status);
  },

  async SEND_WEBSOCKET_MESSAGE(_1, payload) {
    const { action, data } = payload;

    if (!action) {
      console.log(
        'SEND_WEBSOCKET_MESSAGE: action parameter is missing from payload'
      );
      return;
    }

    if (!data) {
      console.log(
        'SEND_WEBSOCKET_MESSAGE: data parameter is missing from payload'
      );
      return;
    }

    websocketService.sendMessage(payload);
  },

  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION({ commit }, { action, callback }) {
    commit(
      WEBSOCKET_EVENT_ADD_SUBSCRIBER,
      { action, callback },
      { root: true }
    );
  },

  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION({ commit }, { action, callback }) {
    commit(
      WEBSOCKET_EVENT_REMOVE_SUBSCRIBER,
      { action, callback },
      { root: true }
    );
  },

  WEBSOCKET_EVENT_HANDLE_MESSAGE({ commit }, message) {
    try {
      const { action, data } = message;

      // Publish message to all subscribers of this action
      commit(WEBSOCKET_EVENT_PUBLISH_MESSAGE, { action, data }, { root: true });
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },

  WEBSOCKET_EVENT_ACQUIRE_LOCK({ dispatch }, bubbleId) {
    try {
      dispatch(
        SEND_WEBSOCKET_MESSAGE,
        { action: 'acquireBubbleLock', data: bubbleId },
        { root: true }
      );
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },

  WEBSOCKET_EVENT_RELEASE_LOCK({ dispatch }, bubbleId) {
    try {
      dispatch(
        SEND_WEBSOCKET_MESSAGE,
        { action: 'releaseBubbleLock', data: bubbleId },
        { root: true }
      );
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },
};

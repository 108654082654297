import client from '../../../config/api';
import {
  LOCAL_SET_BUBBLES,
  LOCAL_REMOVE_BUBBLE,
  LOCAL_UPDATE_BUBBLE,
} from '../mutations/mutationTypes';

// import { GET_TASK } from '../../tasks/actions/actionTypes';
import { SEND_WEBSOCKET_MESSAGE } from '../../websocket/actions/actionTypes';

export default {
  async SAVE_BUBBLES(_1, payload) {
    const { taskId, data } = payload;
    return client.post(`/bubbles/${taskId}`, data);
  },
  async GET_BUBBLES({ commit }, taskId) {
    const result = await client.get(`/bubbles/${taskId}`);
    commit(LOCAL_SET_BUBBLES, result.data);
  },
  async DELETE_BUBBLE({ commit, dispatch }, bubbleId) {
    if (!bubbleId) {
      return;
    }

    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'bubbleDelete', data: { bubbleId } },
      { root: true }
    );

    commit(LOCAL_REMOVE_BUBBLE, bubbleId);
  },
  async CREATE_BUBBLE({ dispatch }, data) {
    console.log('CREATE_BUBBLE');
    const { taskId, bubble } = data;
    if (!taskId || !bubble) {
      return;
    }

    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'bubbleCreate', data: { taskId, ...data } },
      { root: true }
    );
  },
  async UPDATE_BUBBLE({ commit, dispatch }, data) {
    const { _id } = data;
    if (!_id) {
      return;
    }

    dispatch(
      SEND_WEBSOCKET_MESSAGE,
      { action: 'bubbleUpdate', data },
      { root: true }
    );

    // await client.put(`/bubble/${_id}`, data);
    commit(LOCAL_UPDATE_BUBBLE, data);

    // if (taskId) {
    //   await dispatch(GET_TASK, taskId, { root: true });
    // }
  },
};

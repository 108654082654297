import Vue from 'vue';

import {
  UPDATE_WEB_SOCKET_STATUS,
  WEBSOCKET_EVENT_HANDLE_MESSAGE,
} from '../store/websocket/actions/actionTypes';

const websocketService = {
  sendMessage(message) {
    if (Vue.prototype.$socket && Vue.prototype.$socket.sendObj) {
      Vue.prototype.$socket.sendObj(message);
    } else {
      console.error('WebSocket connection is not established.');
    }
  },

  sendRawMessage(message) {
    if (Vue.prototype.$socket && Vue.prototype.$socket.send) {
      Vue.prototype.$socket.send(message);
    } else {
      console.error('WebSocket connection is not established.');
    }
  },

  closeConnection() {
    if (Vue.prototype.$socket) {
      Vue.prototype.$socket.close();
    } else {
      console.error('WebSocket connection is not established.');
    }
  },

  async onOpen() {
    console.log('WebSocket connection opened');
    console.log('WebSocket registration after log in...');

    // The websocket registration will be done after the login
  },

  onClose() {
    console.log('WebSocket connection closed');
    this.$store.dispatch(UPDATE_WEB_SOCKET_STATUS, 'Disconnected');
  },
  onError(error) {
    console.error('WebSocket error:', error);
    this.$store.dispatch(UPDATE_WEB_SOCKET_STATUS, 'Error');
  },
  onMessage(message) {
    const { data } = message;

    // We make sure to parse only json messages
    try {
      const parsedMessage = JSON.parse(data);

      if (parsedMessage.action) {
        console.log('Managing event', parsedMessage.action);

        this.$store.dispatch(WEBSOCKET_EVENT_HANDLE_MESSAGE, parsedMessage);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      console.log(`Received websocket message ${data}`);
    }
  },
};

export default websocketService;
